import React from "react";
import {Switch} from "react-router-dom";
import Loadable from "react-loadable";
import Loading from "./components/loading";
import AppliedRoute from "./components/AppliedRoute";

const LoadingComponent = ({isLoading, error}) => {
    // Handle the loading state
    if (isLoading) {
        return <div>
            <Loading/>
        </div>;
    }
    // Handle the error state
    else if (error) {
        return <div>Sorry, there was a problem loading the page.</div>;
    } else {
        return null;
    }
};

const AsyncLayout= Loadable({loader:() => import('./containers/layout'),loading:LoadingComponent});


export default ({childProps}) =>
    <Switch>
        <AppliedRoute path="/"  component={AsyncLayout} props={childProps}/>
    </Switch>
