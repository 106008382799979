import React from 'react';
import Routes from "./Routes";
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

const trackingId = "UA-173483111-1"; // Replace with your Google Analytics tracking ID

history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
});
class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            isAuthenticating: true
        };
    }
    render() {
        ReactGA.initialize(trackingId);
        const childProps = {
          isAuthenticated: this.state.isAuthenticated,
          userHasAuthenticated: this.userHasAuthenticated,
          setUserHasAuthenticated: this.setUserHasAuthenticated
      };
        return(
            <Routes history={history} childProps={childProps} />
        )
  };
}

export default App;
